<template>
  <a-result status="403" title="403" sub-title="您没有权限访问该网页！">
    <template #extra>
      <a-button type="primary" @click="goBackHome">返回后台首页</a-button>
    </template>
  </a-result>
</template>
<script>
import { Result } from "ant-design-vue";
export default {
  components: {
    AResult: Result,
  },
  setup() {},
  methods: {
    goBackHome() {
      this.$router.replace({ name: "background_page" });
    },
  },
};
</script>
<style lang="less" scoped>
.ant-result {
  padding-top: 132px;
}
</style>